.App {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
}

.PageLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.PageLayout.Content {
  flex: 1 0 auto; /* This will allow the content to grow and shrink as needed */
}

.PageLayout.Footer {
  flex-shrink: 0; /* This will prevent the footer from shrinking */
}

.BlogPosts {
  display: flex;
  text-align: justify;
  justify-content: 'space-between'
}

.BlogPost {
  flex: '0 0 47%';
  margin-right: '3%'  
}

/* Adjust styles to use CSS variables for colors */
:root {
  --background-color: #ffffff; /* Light mode background color */
  --text-color: #000000; /* Light mode text color */
  --background-color-dark: #0d1117; /* Dark mode background color */
  --text-color-dark: #c9d1d9; /* Dark mode text color */
}

[data-color-mode='dark'] {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
}
